
:root {
  /* --navbar-color: #0177bb; */
  --navbar-color: #ce6a0d !important;
  /* --navbar-color: #eee; */
  --navbar-background-dark-color: #191f27;
  --navbar-background-light-color: #eee;
}

.navbar {
  /* background-color: #6bbbc4; */
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  z-index: 20;
  transition: transform 1s ease-in-out;
}

.nav-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80px;
  max-width: 1500px;
}

.nav-logo {
  color: var(--navbar-color);
  align-items: center;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  flex-grow: 1;
}

.nav-menu {
  display: flex;
  list-style: none;
  text-align: center;
  margin-right: 2rem;
}

.nav-links {
  color: var(--navbar-color);
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
  border-bottom: 3px solid transparent;
}


.fa-code {
  margin-left: 1rem;
}

.nav-item {
  color: var(--navbar-color);
  line-height: 40px;
  margin-right: 1rem;
}

.nav-item:after {
  content: "";
  display: block;
  height: 3px;
  width: 0;
  background: transparent;
  transition: width 0.7s ease, background-color 0.5s ease;
}

.nav-item:hover:after {
  width: 100%;
  background: var(--navbar-color);
}

.nav-item .active {
  color: var(--navbar-color);
  /* border: 1px solid var(--navbar-color); */
}

.nav-icon {
  display: none;
}

.navbar-dark {
  background-color: var(--navbar-background-dark-color);
  color: #eee;
}

.navbar-light {
  background-color: var(--navbar-background-light-color);
  color: rgb(15, 14, 14);
}

.avatar {
  border-radius: 50%;
}


@media screen and (max-width: 960px) {
  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-top: 1pxsolid #eee;
    position: absolute;
    top: 80px;
    left: -110%;
    opacity: 1;
    transition: all 0.5s ease;
  }

.nav-item .material-ui-switch {
  margin-left: 1rem !important;
  color: #eee;
}

  .material-ui-switch.active {
    color: #eee;
  }

  .nav-menu.active{
    background: #191f27;
    left: 0px;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }
  .nav-item .active {
    color: var(--navbar-color);
    border: none;
  }
  .nav-links {
    padding: 1.5rem;
    width: 100%;
    display: table;
  }

  .nav-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    color: var(--navbar-color);
  }

}





