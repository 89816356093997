
:root {
  --app-background-light-color:  #eee;
  --app-background-dark-color:#191f27;
  --app-dark-color: #191f27;
  --app-light-color: #eee;

}

* {
  margin: 0;
  padding: 0;
}


/*dark them*/
.light{
  background-color: var(--app-background-light-color);
  color: var(--app-dark-color);
  height: 100%;
  width: 100%;

}

/*light them*/
.dark{

  background-color: var(--app-background-dark-color);
  color: var(--app-light-color);
  height: 100%;
  width: 100%;
}

