.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
}

.video-responsive iframe {
  position: absolute;
  left: 0;
  top: 0;
  width:380px  !important;
  height:250px !important;
}
