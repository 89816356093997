
:root{
    --home-dark-color:  #eee;
    --home-light-color: #35393d;
    --home-background-dark-color: #191f27;
    --home-background-light-color: #eee;
}

.container-dark {
  background-color: var(--home-background-dark-color);
  height: 100% !important;
  margin-bottom: 200px !important;

}

.container-light {
    background-color: var(--navbar-background-light-color);
    height: 100% !important;
    margin-bottom: 200px !important;
}

.avatar-box-dark {
  background-color: var(--home-background-dark-color);
  padding: 100px;
  width: 200px;
  height: 200px;
  display: flex;
  margin: auto;
  }

.avatar-box-light {
  background-color: var(--home-background-light-color);
  padding: 100px;
  width: 200px;
  height: 200px;
  display: flex;
  margin: auto;

}

.avatar-dark {
  background-color: var(--home-background-dark-color);
  width: 200px !important;
  height: 200px !important;
}

.avatar-light {
  background-color: var(--home-background-light-color);
  width: 200px !important;
  height: 200px !important;

}

.box-about-text{

    display: flex;
    align-items: center;
    justify-content: center;

}

.divider-dark{
    background-color: var(--home-background-light-color);
    margin-top: 80px !important;
    margin-bottom: 40px !important;
    margin-left: 160px !important;
    margin-right: 160px !important;
}

.divider-light{
    background-color: var(--home-background-dark-color);
    margin-top: 80px !important;
    margin-bottom: 40px !important;
    margin-left: 160px !important;
    margin-right: 160px !important;
}

.box-about {
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.box-about-text{
    text-align: justify;
    font-size: 20px !important;

}

.box-title-text{
    text-align: center;
    font-size: 30px !important;

}

.box-project-dark{
    background-color: var(--home-background-dark-color) !important;
    color: var(--home-dark-color) !important;
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px !important;
}

.box-project-light{
    background-color: var(--home-background-light-color) !important;
    color: var(--home-light-color) !important;
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px !important;
}



.card-project-dark{
    background-color: #121927  !important;
    color: var(--home-dark-color) !important;
    display: flex;
    flex-direction: column;
    flex-basis: calc(33.33% - 16px);
    margin: 5px 8px 5px 8px;
  /* flex-basis: calc(33.33% - 16px);
  margin: 5px 8px 5px 8px; */

}

.card-project-light{
    background-color: #f5f5f5  !important;
    color: var(--home-light-color) !important;
    display: flex;
    flex-direction: column;
    flex-basis: calc(33.33% - 16px);
    margin: 5px 8px 5px 8px;
  /* flex-basis: calc(33.33% - 16px);
  margin: 5px 8px 5px 8px; */

}


@media (min-width: 768px) {
  .box-project-dark, .box-project-light {
    flex-direction: row;
  }
}

@media (max-width: 767px) {
  .box-project-dark, .box-project-light {
    flex-direction: column;
  }
}


@media (min-width: 768px) {
  .card-project-dark, .card-project-light {
    flex-basis: calc(33.33% - 16px);
  }
}

@media (max-width: 767px) {
  .card-project-dark, .card-project-light {
    flex-basis: 100%;
  }
}



@media screen and (max-width: 960px) {
    .avatar {
      width: 100px !important;
      height: 100px !important;

    }
    .box-about-text {
        font-size: 10px;
      }
    .box-avatar{
     height: 200px !important;
    }
    .avatar-box-dark{
      padding: 50px;
      width: 200px;
      height: 200px;
      display: flex;
      margin: auto;
    }
    .avatar-box-light{
      padding: 50px;
      width: 200px;
      height: 200px;
      display: flex;
      margin: auto;
    }

    .divider-dark, .divider-light{
      margin-top: 40px !important;
      margin-bottom: 20px !important;
      margin-left: 40px !important;
      margin-right: 40px !important;
    }


  }
