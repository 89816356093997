

/* make this stick to the buttom */


.footer-light{
    background-color: #eee !important;
    color: #35393d !important;
    /* position: relative !important; */


}


.footer-dark{
    background-color: #191f27 !important;
    color:  #eee  !important;
    position: relative !important;
    width: 100% !important;
    bottom: 0 !important;


}

.footer-text-dark{
    color:   rgba(175, 170, 170, 0.527)  !important;
    text-align: center;
    margin-top: 10px !important;
}

.footer-text-light{
    color: #35393d !important;
    text-align: center;
    margin-top: 10px !important;
}


.icon-light{

    color: #35393d !important;
    font-size: large;
    margin-top: 10px !important;
}

.icon-dark{
    color: rgba(175, 170, 170, 0.527)  !important;
    font-size: large;
    margin-top: 10PX !important;
}

.icon-dark:hover{
    color: #b85d09 !important;
}

.icon-light:hover{
    color: #b85d09 !important;
}


.root-footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #eee;
  }

  @media only screen and (max-width: 600px) {
    .root-footer {
      flex-direction: column;
      justify-content: flex-start;
    }
  }



@media screen and (max-width: 960px) {
    .footer-light{
        background-color: #eee !important;
        color: #35393d !important;
        position: relative !important;
        bottom: 0 !important;


    }


    .footer-dark{
        background-color: #191f27 !important;
        color:  #eee  !important;
        position: relative !important;
        bottom: 0 !important;

    }

    .footer-text-dark{
        color:   rgba(175, 170, 170, 0.527)  !important;
        text-align: center;
        margin-top: 20px !important;
        font-size: 10px !important;
    }

    .footer-text-light{
        color: #35393d !important;
        text-align: center;
        margin-top: 20px !important;
        font-size: 10px !important;
    }


    .icon-light{

        color: #35393d !important;
        /* font-size: large; */
        margin-top: 20px !important;
        font-size: 10px !important;
    }

    .icon-dark{
        color: rgba(175, 170, 170, 0.527)  !important;
        /* font-size: large; */
        margin-top: 20PX !important;
        font-size: 13px !important;
    }

    }
